import React, { useState, useRef } from 'react';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import MessageIcon from '@mui/icons-material/Message';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';

import {
  makeStyles,
  alpha,
  Box,
  List,
  Link,
  AppBar,
  Hidden,
  Toolbar,
  Container,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Drawer,
  ListItem
} from '@material-ui/core';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/slices/authJwt';
import qrata from '../../src/qrataLogo.svg';
import { NavLink as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';

const MENU_LINKS = [
  {
    title: 'Communities',
    href: PATH_APP.general.communities
  },
  {
    title: 'Opportunities',
    href: PATH_APP.general.opportunities
  },
  {
    title: 'Standout',
    href: PATH_APP.general.standout
  },
 
];

const MOBILE_MENU_LINKS = [
  {
    title: 'Communities',
    icon: <GroupsIcon/>,
    href: PATH_APP.general.communities
  },
  {
    title: 'Opportunities',
    icon: <ExploreOutlinedIcon />,
    href: PATH_APP.general.opportunities
  },
  {
    title: 'Standout',
    icon: <MessageIcon />,
    href: PATH_APP.general.standout
  }
];

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {},
  AppBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: 0
    }
  },
  mobileHeader: {
    backgroundColor: '#003458',
    top: 0,
    bottom: 'auto'
  },
  mobileToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  drawerPaper: {
    width: drawerWidth
  },
 
  listItemText: {
    color: "#003458",
    '&:hover': {
      color: 'rgb(54, 176, 154)'
    }
  },
  listItemIcon: {
    color: '#003458',
    '&:hover': {
      color: 'rgb(54, 176, 154)'
    }
  }

}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

function TopBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isHome = pathname === '/';

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('modalClosed');
    dispatch(logout()).then(() => {
      handleClose();
      history.push('/auth/login');
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderDrawer = (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      classes={{ paper: classes.drawerPaper }}
     
    >
      <List>
        {MOBILE_MENU_LINKS.map((link) => (
          <ListItem
            button
            key={link.title}
            component={RouterLink}
            to={link.href}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.title} classes={{ primary: classes.listItemText }} />
          </ListItem>
        ))}
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary="Account" classes={{ primary: classes.listItemText }} />
        </ListItem>
      </List>
      <StyledMenu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem button component={RouterLink} to={PATH_APP.general.talentCardNew} onClick={handleClose}>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Talent Card" classes={{ primary: classes.listItemText }} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" classes={{ primary: classes.listItemText }} />
        </ListItem>
      </StyledMenu>
    </Drawer>
  );

  return (
    <React.Fragment>
      <Hidden smDown>
        <AppBar color="primary" className={classes.AppBar}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Container className={classes.toolbarContainer} maxWidth="lg">
              <RouterLink
                to="/app/opportunities"
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <img src={qrata} alt="logo" width="75px" />
                <div style={{ width: '1px', background: 'white', height: '25px' }}></div>
                <Typography style={{ color: 'white' }}>
                  <span style={{ fontSize: '20px' }}>For Talent</span>
                </Typography>
              </RouterLink>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {MENU_LINKS.map((link, index) => (
                  <Box key={index} variant="contained" sx={{ '&:hover': {
                    color: 'rgb(54, 176, 154)'
                  }}}>
                    <Link
                      exact
                      to={link.href}
                      key={link.title}
                      underline="none"
                      variant="subtitle1"
                      color="textPrimary"
                      component={RouterLink}
                      sx={{ mr: 5,
                        '&:hover': {
                          color: 'rgb(54, 176, 154)'
                        }
                       }}
                    >
                      <IconButton style={{ color: 'unset',  '&:hover': {
      color: 'rgb(54, 176, 154)'
    } }}>{link.icon}</IconButton>
                      {link.title}
                    </Link>
                  </Box>
                ))}
                <div
                  style={{
                    width: '1px',
                    height: '35px',
                    background: 'grey',
                    marginLeft: '10px',
                    borderRadius: '5px'
                  }}
                ></div>
                <IconButton
                id='accountCircle'
                  edge="end"
                  aria-controls="demo-customized-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <StyledMenu
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <ListItem button component={RouterLink} to={PATH_APP.general.talentCardNew} onClick={handleClose}>
                    <ListItemIcon>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Talent Card" classes={{ primary: classes.listItemText }} />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" classes={{ primary: classes.listItemText }} />
                  </ListItem>
                </StyledMenu>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden smUp>
        <AppBar position="fixed" className={classes.mobileHeader}>
          <Toolbar className={classes.mobileToolbar}>
            <RouterLink
              to="/app/opportunities"
              style={{ display: 'flex', textDecoration: 'none', color: "white" }}
            >
              <img src={qrata} width="50px" alt="logo" />
            </RouterLink>
            <IconButton
              edge="end"
              aria-label="menu"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="#fff !important"
              style={{ color: "#f7f7f7", width: "30px", height: "30px" }}
            >
              <MenuIcon  />
            </IconButton>
          </Toolbar>
        </AppBar>
        {renderDrawer}
      </Hidden>
    </React.Fragment>
  );
}

export default TopBar;
