import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInitialize } from 'src/redux/slices/authJwt';
import {getInitializeUserComm} from "src/redux/slices/userComm";

// ----------------------------------------------------------------------

JwtProvider.propTypes = {
  children: PropTypes.node
};

function JwtProvider({ children }) {
  const dispatch = useDispatch();
  console.log("JWT provider")
  useEffect(() => {
    dispatch(getInitialize());
     dispatch(getInitializeUserComm());
    console.log("userComm")
   
  }, [dispatch]);

  return <>{children}</>;
}

export default JwtProvider;
