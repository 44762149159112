import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {updateUser} from "src/redux/slices/userComm";
const communityApiBaseUrl = process.env.REACT_APP_API_COMM_URL;
console.log(communityApiBaseUrl)
const initialState = {
  isLoading: false,
  error: null,
  community: null,
};

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCommunitySuccess(state, action) {
      state.isLoading = false;
      state.community = action.payload;
    },
    updateCommunitySuccess(state, action) {
      state.isLoading = false;
      state.community = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  getCommunitySuccess,
  updateCommunitySuccess,
} = communitySlice.actions;

export default communitySlice.reducer;

// Thunk to fetch community data
export function fetchCommunityById(communityId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      console.log(communityId);
      const response = await axios.get(`${communityApiBaseUrl}/c/community/${communityId}`);
      dispatch(getCommunitySuccess(response.data));
    } catch (error) {
      // Log the error to the console for debugging
      console.error('Error fetching community:', error);
      // Extract a user-friendly error message
      const errorMessage = error.response?.data?.message || 'An error occurred while fetching the community data.';
      dispatch(hasError(errorMessage));
    }
  };
}

// Thunk to join a community
export function joinCommunity(communityId ,userId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`${communityApiBaseUrl}/c/community/${communityId}/join` , {userId});
      dispatch(updateCommunitySuccess(response.data));
      dispatch(fetchCommunityById(communityId));
     // dispatch(updateUser(userId)) // Fetch updated community data after joining
      console.log("joined")
    } catch (error) {
      // Log the error to the console for debugging
      console.error('Error joining community:', error);
      // Extract a user-friendly error message
      const errorMessage = error.response?.data?.message || 'An error occurred while joining the community.';
      dispatch(hasError(errorMessage));
    }
  };
}

// Thunk to leave a community
export function leaveCommunity(communityId , userId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`${communityApiBaseUrl}/c/community/${communityId}/leave` , {userId});
      dispatch(updateCommunitySuccess(response.data));
      dispatch(fetchCommunityById(communityId));
      //dispatch(updateUser(userId))
      console.log("left") // Fetch updated community data after leaving
    } catch (error) {
      // Log the error to the console for debugging
      console.error('Error leaving community:', error);
      // Extract a user-friendly error message
      const errorMessage = error.response?.data?.message || 'An error occurred while leaving the community.';
      dispatch(hasError(errorMessage));
    }
  };
}
