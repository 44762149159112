// ----------------------------------------------------------------------

import opportunities from "src/redux/slices/opportunities";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    register: path(ROOTS.auth, '/register'),
    forgetPassword: path(ROOTS.auth, '/forget-password'),
    resetPassword: path(ROOTS.auth, '/reset-password/:token'),
    googleLoginAndRegister: path(ROOTS.auth, '/social/:token/:path'),
    verify: path(ROOTS.auth, '/verify'),
   
    talentDetails: path(ROOTS.auth, '/talentDetails'),
    emailValidate: path(ROOTS.auth, '/emailValidate')
    // emailValidate: path(ROOTS.auth, '/emailValidate')
  },
  comingSoon: '/coming-soon'
};
export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    talentCard: path(ROOTS.app, '/talent-card'),
    talentCardById:path(ROOTS.app , "/talentCard/:id"),
    talentCardNew: path(ROOTS.app, "/talentCard"),
    communities:path(ROOTS.app ,'/communities' ),
    communities2:path(ROOTS.app ,'/communities/:name/:id' ),
    communities3:path(ROOTS.app ,'/communities/all' ),
    talentCardBackground: path(ROOTS.app, '/talent-card/background'),
    talentCardHardSkills: path(ROOTS.app, '/talent-card/hard-skills'),
    talentCardSoftSkills: path(ROOTS.app, '/talent-card/soft-skills'),
    // talentCardBooster: path(ROOTS.app, '/talent-card/boosters'),
    talentCardBoosterNew: path(ROOTS.app, '/talent-card/BoosterNew'),
    opportunities: path(ROOTS.app, '/opportunities'),
   
    opportunities2: path(ROOTS.app, '/opportunities2'),
    opportunitiesTabs: path(ROOTS.app, '/JobTabs'),
    opportunitiesDetail: path(ROOTS.app, '/opportunities/:id'),
    opportunitiesDetailById: path(ROOTS.app, '/opportunities/j394uejlsjw0u9'),
    inbox: path(ROOTS.app, '/inbox'),
    standout: path(ROOTS.app, '/standout'),
    personalityAssessmentDetails: path(ROOTS.app, '/standout/personalityAssessment/details'),
    personalityAssessmentShortAssessment: path(ROOTS.app, '/standout/personalityAssessment/shortAssessment'),
    personalityAssessmentShortResults: path(ROOTS.app, '/standout/personalityAssessment/shortResults'),
    personalityAssessmentLongAssessment: path(ROOTS.app, '/standout/personalityAssessment/longAssessment'),
    personalityAssessmentLongResults: path(ROOTS.app, '/standout/personalityAssessment/longResults'),
  }
};
