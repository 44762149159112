import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import authJwtReducer from './slices/authJwt';
import opportunitiesReducer from './slices/opportunities';
import userReducer from './slices/user';
import communityReducer from './slices/community'; // Import community reducer here
import userCommReducer from './slices/userComm';
import allCommunitiesReducer from "./slices/allCommunities"
import postsReducer from "./slices/posts"


// Configure persistence for root and authJwt reducers
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings', 'user'], // Add 'community' if needed to persist community state
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated'],
};

// Combine all reducers
const rootReducer = combineReducers({
  settings: settingsReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  opportunities: opportunitiesReducer,
  user: persistReducer(rootPersistConfig, userReducer),
  community: communityReducer,
  userComm: userCommReducer ,
  allCommunities: allCommunitiesReducer,
  posts: postsReducer,

});

export { rootPersistConfig, rootReducer };


