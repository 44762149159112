import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const communityApiBaseUrl = process.env.REACT_APP_API_COMM_URL;
console.log(communityApiBaseUrl)
const initialState = {
  isLoading: false,
  error: null,
  posts: [],
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },
    deletePostSuccess(state, action) {
      state.posts = state.posts.filter(post => post._id !== action.payload);
    },
    updatePostSuccess(state, action) {
      state.posts = state.posts.map(post =>
        post._id === action.payload._id ? action.payload : post
      );
    },
    createPostSuccess(state, action) {
      state.posts = [action.payload, ...state.posts]; // Add the new post to the beginning of the array
    },
    addCommentToPostSuccess(state, action) {
      const { postId, comment } = action.payload;
      state.posts = state.posts.map(post =>
        post._id === postId ? { ...post, comments: [...post.comments, comment] } : post
      );
    },
    deleteCommentToPostSuccess(state, action) {
      const { postId, commentId } = action.payload;
    
      
      state.posts = state.posts.map(post => {
        
        if (post._id === postId) {
          return {
            ...post,
            comments: post.comments.filter(comment => comment._id !== commentId),
          };
        }
     
        return post;
      });
    },
    

    upvoteCommentSuccess(state, action) {
      const { postId, commentId, comment } = action.payload;
      
      console.log("Before Update:", JSON.stringify(state.posts, null, 2)); // Log state before update
    
      state.posts = state.posts.map(post => {
        if (post._id === postId) {
          return {
            ...post,
            comments: post.comments.map(c => {
              console.log("Comparing comments:", c._id, commentId); // Log the comparison
              return c._id === commentId ? comment : c;
            }), // Update the specific comment
          };
        }
        return post;
      });
    
      console.log("After Update:", JSON.stringify(state.posts, null, 2)); // Log state after update
    },
    
    
    downvoteCommentSuccess(state, action) {
      const { postId, commentId, comment } = action.payload;
      state.posts = state.posts.map(post => {
        if (post._id == postId) {
          return {
            ...post,
            comments: post.comments.map(c => (c._id == commentId ? comment : c)), // Update the specific comment
          };
        }
        return post;
      });
    },
    
   
  
    // Handle API error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
 

});

export const {
  startLoading,
  hasError,
  getPostsSuccess,
  updatePostSuccess,
  createPostSuccess,
  addCommentToPostSuccess,
  upvoteCommentSuccess,
  downvoteCommentSuccess,
  deletePostSuccess,
  deleteCommentToPostSuccess
} = postsSlice.actions;

export default postsSlice.reducer;




// const token = localStorage.getItem('accessToken'); // Get the token from storage

// const config = {
//   headers: {
//     'Authorization': `Bearer ${token}`
//   }
// };



const token = localStorage.getItem('accessToken');
const apiClient = axios.create({
  baseURL: communityApiBaseUrl,
  headers: {
    'Authorization': `Bearer ${token}`,
  }
});


// Thunk to fetch posts by community ID
export function fetchPostsByCommunityId(communityId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`${communityApiBaseUrl}/p/posts/community/${communityId}`);
      dispatch(getPostsSuccess(response.data));
    } catch (error) {
      console.error('Error fetching posts:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while fetching the posts.';
      dispatch(hasError(errorMessage));
    }
  };
}

// Thunk to upvote a post and fetch the updated post
export function upvotePost(postId, userId) {
  return async (dispatch) => {
   
  
    try {
      await apiClient.post(`${communityApiBaseUrl}/p/posts/${postId}/upvote`, { userId } );
      const response = await axios.get(`${communityApiBaseUrl}/p/posts/${postId}`);
      dispatch(updatePostSuccess(response.data)); // Update the specific post
    } catch (error) {
      console.error('Error upvoting post:', error);
      // Handle error as needed
      const errorMessage = error.response?.data?.message || 'An error occurred while upvoting the post.';
      dispatch(hasError(errorMessage));
    }
  };
}

export function removeUpvotePost(postId, userId) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken'); // Get the token from storage

   
    try {
      await apiClient.post(`${communityApiBaseUrl}/p/posts/${postId}/removeUpvote`, { userId } );
      const response = await axios.get(`${communityApiBaseUrl}/p/posts/${postId}`);
      dispatch(updatePostSuccess(response.data)); // Update the specific post
    } catch (error) {
      console.error('Error upvoting post:', error);
      // Handle error as needed
      const errorMessage = error.response?.data?.message || 'An error occurred while upvoting the post.';
      dispatch(hasError(errorMessage));
    }
  };
}
export function createPost(postData) {
  console.log(postData)
  return async (dispatch) => {
      dispatch(startLoading());
      const token = localStorage.getItem('accessToken'); // Get the token from storage

      const config = {
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data', // Specify multipart/form-data for file uploads
          },
      };

      try {
          // Post request
          const response = await apiClient.post(`${communityApiBaseUrl}/p/posts`, postData, );
          const  postId  = response.data?.post?._id; // Extract postId from the response

          // Get request to fetch the newly created post
         
          const postResponse = await axios.get(`${communityApiBaseUrl}/p/posts/${postId}`);
          console.log(postResponse )
          dispatch(createPostSuccess(postResponse.data)); // Add the new post to the state
      } catch (error) {
          console.error('Error creating post:', error);
          const errorMessage = error.response?.data?.message || 'An error occurred while creating the post.';
          dispatch(hasError(errorMessage));
      }
  };
}

// Thunk to add a comment to a post
export function addCommentToPost(postId, userId, comment) {
  return async (dispatch) => {

    try {
      const response = await apiClient.post(`${communityApiBaseUrl}/a/comments`, {
        postId,
        userId,
        content: comment
      } );

      const { commentId } = response.data; // Extract commentId from the response
      const commentResponse = await axios.get(`${communityApiBaseUrl}/a/comments/${commentId}`);
      console.log(commentResponse , "kikii") // Fetch the newly created comment
      const newComment = commentResponse.data;
      
      dispatch(addCommentToPostSuccess({ postId, comment: newComment })); // Update the post with the new comment
    } catch (error) {
      console.error('Error adding comment:', error);
      // Handle error as needed
    }
  };
}


// Thunk to upvote a comment and fetch the updated comment
export function upvoteComment(postId, commentId, userId) {
  return async (dispatch) => {
  

    try {
      // Upvote the comment
      await apiClient.post(`${communityApiBaseUrl}/a/comments/${commentId}/upvote`, { userId });

      // Fetch the updated comment
      const commentResponse = await axios.get(`${communityApiBaseUrl}/a/comments/${commentId}`);

      const updatedComment = commentResponse.data;
      console.log(updatedComment)
      // Dispatch the success action to update the comment in the store
      dispatch(upvoteCommentSuccess({ postId, commentId, comment: updatedComment }));
    } catch (error) {
      console.error('Error upvoting comment:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while upvoting the comment.';
      dispatch(hasError(errorMessage));
    }
  };
}






// Thunk to downvote a comment and fetch the updated comment
export function downvoteComment(postId, commentId, userId) {
  return async (dispatch) => {
    

    try {
      // Downvote the comment
      await apiClient.post(`${communityApiBaseUrl}/a/comments/${commentId}/downvote`, { userId });

      // Fetch the updated comment
      const commentResponse = await axios.get(`${communityApiBaseUrl}/a/comments/${commentId}`);

      const updatedComment = commentResponse.data;

      // Dispatch the success action to update the comment in the store
      dispatch(downvoteCommentSuccess({ postId, commentId, comment: updatedComment }));
    } catch (error) {
      console.error('Error downvoting comment:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while downvoting the comment.';
      dispatch(hasError(errorMessage));
    }
  };
}

export function deletePost(postId) {
  
  return async (dispatch) => {
      dispatch(startLoading());
      // const token = localStorage.getItem('accessToken'); // Get the token from storage

      // const config = {
      //     headers: {
      //         'Authorization': `Bearer ${token}`,
      //         'Content-Type': 'multipart/form-data', // Specify multipart/form-data for file uploads
      //     },
      // };

      try {
          // Post request
          const response = await apiClient.delete(`${communityApiBaseUrl}/p/posts/${postId}/delete`);
         
          dispatch(deletePostSuccess(postId)); 
      } catch (error) {
          console.error('deleteing post:', error);
          const errorMessage = error.response?.data?.message || 'An error occurred while deleting the post.';
          dispatch(hasError(errorMessage));
      }
  };
}

export function deleteCommentToPost(postId, commentId) {
  return async (dispatch) => {
   
  
    try {
      const response = await apiClient.delete(`${communityApiBaseUrl}/a/comments/${commentId}/delete`);

     
      
      dispatch(deleteCommentToPostSuccess({ postId, commentId})); // Update the post with the new comment
    } catch (error) {
      console.error('Error adding comment:', error);
      // Handle error as needed
    }
  };
}